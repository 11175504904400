

















import { Component, Mixins, Vue } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'
import LessonStudent from '@/mixins/action-cable/LessonStudent/index'

@Component({
  components: {
    SidebarSwitcher,
    ButtonBase,
  },
})
export default class ClassModes extends Mixins(ClassModeChangeAble, LessonStudent) {
  private transition(classModeCode: string): void {
    Vue.prototype.$logger.info(`-- transition ${classModeCode}`)
    this.noticeOrStackCurrentPage({ classModeCode: classModeCode })
    this.$router.push(`/student/dashboard?mode=${classModeCode}`)
  }

  private async mounted(): Promise<void> {
    await this.checkClassModeAndSideMenuMode()
  }
}
